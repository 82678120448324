import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  IconButton, TextField, Typography, Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { textValidator } from '../../utils/Validator';
import OtherSection from './OtherSection';

export interface IEvent {
  target: {
    value: string,
    name: string,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function OptionList(props: any) {
  const {
    options, onChange, otherOnchange, setOtherOptionData, questionState,
  } = props;
  const classes = useStyles();

  const changeOptions = (newData: Array<any>) => {
    onChange({
      value: [...newData],
      name: 'options',
    });
  };

  const onOptionChange = (e: IEvent, index: number) => {
    const { name, value } = e.target;
    const newOptions = [...options];
    newOptions[index][name] = textValidator(value);
    changeOptions([...newOptions]);
  };
  const onAddOption = () => {
    const lastIndex = options.length;
    const newOptionObj = {
      option: '',
      weightage: 0,
      value: lastIndex,
    };
    changeOptions([...options, { ...newOptionObj }]);
  };
  const onRemoveOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    changeOptions([...newOptions]);
  };

  return (
    <List subheader={<ListSubheader>Options</ListSubheader>} className={classes.root}>
      {Array.isArray(options) && options.map((option, index) => {
        const { option: optionText, weightage } = option;
        if (!option?.isOther) {
          return (
            <ListItem alignItems="center" key={`question-option-${index}`}>
              <Typography style={{
                paddingRight: '8px',
              }}
              >
                {`${index + 1}.`}
              </Typography>
              <TextField
                placeholder="Enter an answer choice"
                type="text"
                variant="outlined"
                value={optionText}
                multiline
                name="option"
                onChange={(e) => onOptionChange(e, index)}
                style={{
                  width: '75%',
                }}
              />
              <ListItemSecondaryAction>
                <TextField
                  placeholder="weightage"
                  type="number"
                  variant="outlined"
                  value={weightage}
                  name="weightage"
                  onChange={(e) => onOptionChange(e, index)}
                  style={{
                    maxWidth: '100px',
                  }}
                />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={onAddOption}
                >
                  <AddIcon />
                </IconButton>
                {options.length > 1
                  && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={onRemoveOption.bind(null, index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
      })}

      <>
        <Divider
          variant="middle"
          style={{
            marginTop: '15px',
            marginBottom: '10px',
          }}
        />
        <OtherSection setOtherOptionData={setOtherOptionData} onChange={onChange} otherOnchange={otherOnchange} optionData={options.find((item: any) => item?.isOther === true)} questionState={questionState} />
      </>
    </List>
  );
}
