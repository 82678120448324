import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField, Typography } from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { COMMENT_TYPE } from '../../constants/Constants';
import { IEvent } from './OptionList';
import './index.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
const OtherSection = (props: any) => {
  const {
    onChange, otherOnchange, optionData, setOtherOptionData, questionState,
  } = props;
  const classes = useStyles();
  const [selectedRadio, setSelectedRadio] = useState(questionState?.commentType || null);
  const [isOther, setIsOther] = useState(!!questionState?.commentType);
  const [isRequired, setIsRequired] = useState(questionState?.comment?.isRequired ? 'Yes' : 'No');
  const [optionText, setOptionText] = useState(optionData?.option || questionState?.comment?.label || null);
  const [placeHolder, setPlaceHolder] = useState(optionData?.placeholderText || questionState?.comment?.placeholderText || null);

  useEffect(() => {
    setOtherOptionData(optionData);
    onChange({ name: 'commentType', value: selectedRadio });
    if (questionState?.comment?.isRequired) {
      const data = {
        option: questionState?.comment?.label,
        isOther: true,
        isRequired: questionState?.comment?.isRequired,
        placeholderText: questionState?.commnet?.placeholderText,
      };
      setOtherOptionData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectCommentType = (value: any) => {
    setSelectedRadio(value);
    onChange({
      value,
      name: 'commentType',
    });
  };

  const onSelectCheckBox = (checked: any) => {
    setIsOther(checked);
    setOptionText(null);
    setPlaceHolder(null);
    otherOnchange({ name: 'isOther', value: checked });
    if (!checked) {
      setSelectedRadio('');
      setIsRequired('null');
      setOtherOptionData(null);
      onChange({
        value: null,
        name: 'commentType',
      });
    }
  };

  const onOptionChange = (e: IEvent) => {
    const { name, value } = e.target;
    if (name === 'label') {
      setOptionText(value);
    }
    if (name === 'placeHolder') {
      setPlaceHolder(value);
    }
    if (name === 'isRequired') {
      otherOnchange({ name, value: value === 'Yes' });
    } else {
      otherOnchange({ name, value });
    }
  };

  return (
    <div className={classes.root}>
      <div className="checkBox-container">
        <Checkbox
          defaultChecked={isOther}
          onChange={(e) => onSelectCheckBox(e.target.checked)}
        />
        <Typography style={{
          paddingRight: '8px',
        }}
        >
          Add an "Other" Answer Option or Comment Field
        </Typography>
      </div>
      {isOther && (
        <div className="other-container">
          <Typography style={{
            paddingRight: '8px',
            fontFamily: 'bold',
            fontWeight: 800,
          }}
          >
            Label
          </Typography>
          <TextField
            placeholder="Other(Please specify)"
            type="text"
            variant="outlined"
            multiline
            name="label"
            value={optionText}
            onChange={(e) => onOptionChange(e)}
            style={{
              width: '50%',
            }}
          />
          <Typography style={{
            paddingRight: '8px',
            fontFamily: 'bold',
            fontWeight: 800,
          }}
          >
            Placeholder Text
          </Typography>
          <TextField
            placeholder="Placeholder for input field"
            type="text"
            variant="outlined"
            multiline
            name="placeHolder"
            value={placeHolder}
            onChange={(e) => onOptionChange(e)}
            style={{
              width: '50%',
            }}
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selectedRadio || ''}
            name="radio-buttons-group"
            onChange={(e) => selectCommentType(e.target.value)}
          >
            <FormControlLabel value={COMMENT_TYPE.ANSWER} control={<Radio />} label="Display as answer choice" />
            <FormControlLabel value={COMMENT_TYPE.COMMENT} control={<Radio />} label="Display as comment field" />
          </RadioGroup>
          {selectedRadio === COMMENT_TYPE.COMMENT && (
            <RadioGroup
              className="isRequired-radio"
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={isRequired}
              name="isRequired"
              onChange={(e) => onOptionChange(e)}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Required Field" />
              <FormControlLabel value="No" control={<Radio />} label="Not Required Field" />
            </RadioGroup>
          )}
        </div>
      )}

    </div>
  );
};

export default OtherSection;
