import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import './index.scss';
import { UI_CONFIG } from '../../../constants/Constants';

export default class FullSpinner extends Component {
  componentDidMount() {
    const { functionReturningPromise, onResolve, onReject } = this.props;
    setTimeout(() => {
      functionReturningPromise && functionReturningPromise()
        .then(onResolve)
        .catch(onReject);
    });
  }

  render() {
    return (
      <div className="fullscreen-spinner">
        <CircularProgress size={UI_CONFIG.loaderSize} />
      </div>
    );
  }
}
