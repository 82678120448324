import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { QuestionnaireRepository } from '../../api/repositories/Questionnaire';
import * as ACTION from './actions';

interface ISignInAction {
    type: string;
    payload: {
        email: string,
        password: string,
    };
}

// getAllQuestionnaire
//   :
// getQuestionnaireById
// createQuestionnaire
// putQuestionnaire
// createQuestion
// putQuestion
// getAllQuestions

export function* createQuestionnaireJob(payload: any): any {
  const data = {
    questionnaire: { ...payload.payload },
  };
  const response: any = yield call(QuestionnaireRepository.createQuestionnaire, data);
  if (response) {
    yield put(ACTION.createQuestionnaireSuccess(response));
    yield put(ACTION.getAllQuestionnaire(1));
  } else {
    yield put(ACTION.createQuestionnaireFailure(response));
  }
}

export function* getQuestionsJob(payload: any): any {
  const { payload: { id } } = payload;
  const response: any = yield call(QuestionnaireRepository.getAllQuestions, id);
  if (response) {
    yield put(ACTION.getQuestionsSuccess(response));
  } else {
    yield put(ACTION.getQuestionsFailure(response));
  }
}

export function* getQuestionnaireJob(payload: any): any {
  const { payload: { questionnaire: { id } } } = payload;
  const response: any = yield call(QuestionnaireRepository.getQuestionnaireById, id);
  if (response) {
    yield put(ACTION.getQuestionnaireSuccess(response));
  } else {
    yield put(ACTION.getQuestionnaireFailure(response));
  }
}

export function* getAllQuestionnaireJob(payload: any): any {
  const { questionnaireListPageCount: page } = payload.payload;
  const response: any = yield call(QuestionnaireRepository.getAllQuestionnaire, page);
  if (response) {
    yield put(ACTION.getAllQuestionnaireSuccess(response));
  } else {
    yield put(ACTION.getAllQuestionnaireFailure(response));
  }
}

export function* putQuestionnaireJob(payload: any): any {
  const {
    title,
    description,
    languageCode,
    allowMultipleResponses,
    id, category,
    isPublished,
  } = payload.payload;
  const data = {
    questionnaire: {
      title,
      description,
      languageCode,
      allowMultipleResponses,
      category,
      isPublished,
    },
  };
  const response: any = yield call(QuestionnaireRepository.putQuestionnaire, id, data);
  if (response) {
    yield put(ACTION.putQuestionnaireSuccess(response));
    yield put(ACTION.getAllQuestionnaire(1));
  } else {
    yield put(ACTION.putQuestionnaireFailure(response));
  }
}

export function* watchQuestionnaireActions(): any {
  yield takeEvery(ACTION.QuestionnaireTypes.CREATE_QUESTIONNAIRE, createQuestionnaireJob);
  yield takeEvery(ACTION.QuestionnaireTypes.GET_QUESTIONS, getQuestionsJob);
  yield takeEvery(ACTION.QuestionnaireTypes.GET_QUESTIONNAIRE, getQuestionnaireJob);
  yield takeEvery(ACTION.QuestionnaireTypes.GET_QUESTIONNAIRE_LIST, getAllQuestionnaireJob);
  yield takeEvery(ACTION.QuestionnaireTypes.PUT_QUESTIONNAIRE, putQuestionnaireJob);
}
