import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import img from '../../../assets/undraw_page_not_found_su7k.svg';
import './index.scss';
import { ROUTE_NAME } from '../../../constants/UserConstants';

interface IProps {
    history: {
        push: (url: string) => void,
    };
    homepage?: boolean;
}

const navigateBack = (history: any) => {
  history.push(ROUTE_NAME.mainRoute);
};

export default memo((props: IProps) => {
  const { history, homepage } = props;
  const navigateBackToHome = () => navigateBack(history);
  return (
    <>
      <div className="errorDiv">
        <img alt="error" src={img} style={{ width: '100%', height: '75vh' }} />
      </div>
      {
        homepage
          && (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Button className="button_action" onClick={navigateBackToHome}>
              Go to homepage
            </Button>
          </div>
          )
      }
    </>
  );
});

