import {
  IQuestionnairePayload, IQuestionnaireResponse, IAllQuestionnaire, IQuestion,
} from '../../api/models/Questionnaire';
import { IQuestionsResponse } from '../../api/models/Survey';

export const QuestionnaireTypes = {
  USER_LOGOUT: 'USER_LOGOUT',
  CREATE_QUESTIONNAIRE: 'CREATE_QUESTIONNAIRE',
  CREATE_QUESTIONNAIRE_SUCCESS: 'CREATE_QUESTIONNAIRE_SUCCESS',
  CREATE_QUESTIONNAIRE_FAILURE: 'CREATE_QUESTIONNAIRE_FAILURE',
  PUT_QUESTIONNAIRE: 'PUT_QUESTIONNAIRE',
  PUT_QUESTIONNAIRE_SUCCESS: 'PUT_QUESTIONNAIRE_SUCCESS',
  PUT_QUESTIONNAIRE_FAILURE: 'PUT_QUESTIONNAIRE_FAILURE',
  GET_QUESTIONNAIRE: 'GET_QUESTIONNAIRE',
  GET_QUESTIONNAIRE_SUCCESS: 'GET_QUESTIONNAIRE_SUCCESS',
  GET_QUESTIONNAIRE_FAILURE: 'GET_QUESTIONNAIRE_FAILURE',
  GET_QUESTIONS: 'GET_QUESTIONS',
  GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_FAILURE: 'GET_QUESTIONS_FAILURE',
  CREATE_QUESTIONS: 'CREATE_QUESTIONS',
  CREATE_QUESTIONS_SUCCESS: 'CREATE_QUESTIONS_SUCCESS',
  CREATE_QUESTIONS_FAILURE: 'CREATE_QUESTIONS_FAILURE',
  PUT_QUESTIONS: 'PUT_QUESTIONS',
  PUT_QUESTIONS_SUCCESS: 'PUT_QUESTIONS_SUCCESS',
  PUT_QUESTIONS_FAILURE: 'PUT_QUESTIONS_FAILURE',
  GET_QUESTIONNAIRE_LIST: 'GET_QUESTIONNAIRE_LIST',
  GET_QUESTIONNAIRE_LIST_SUCCESS: 'GET_QUESTIONNAIRE_LIST_SUCCESS',
  GET_QUESTIONNAIRE_LIST_FAILURE: 'GET_QUESTIONNAIRE_LIST_FAILURE',
  RESET: 'RESET',
};

export const logout = () => ({
  type: QuestionnaireTypes.USER_LOGOUT,
});

export const createQuestionnaire = (payload: IQuestionnairePayload):
{ type: string, payload: IQuestionnairePayload } => ({
  type: QuestionnaireTypes.CREATE_QUESTIONNAIRE,
  payload,
});

export const createQuestionnaireSuccess = (payload: IQuestionnaireResponse):
{ type: string, payload: IQuestionnaireResponse } => ({
  type: QuestionnaireTypes.CREATE_QUESTIONNAIRE_SUCCESS,
  payload,
});

export const createQuestionnaireFailure = (payload: any):
{ type: string, payload: any } => ({
  type: QuestionnaireTypes.CREATE_QUESTIONNAIRE_FAILURE,
  payload,
});

export const putQuestionnaire = (payload: string):
{ type: string, payload: string } => ({
  type: QuestionnaireTypes.PUT_QUESTIONNAIRE,
  payload,
});

export const putQuestionnaireSuccess = (payload: IQuestionnaireResponse):
{ type: string, payload: IQuestionnaireResponse } => ({
  type: QuestionnaireTypes.PUT_QUESTIONNAIRE_SUCCESS,
  payload,
});

export const putQuestionnaireFailure = (payload: any):
{ type: string, payload: any } => ({
  type: QuestionnaireTypes.PUT_QUESTIONNAIRE_FAILURE,
  payload,
});

export const getQuestionnaire = (payload: string):
{ type: string, payload: string } => ({
  type: QuestionnaireTypes.GET_QUESTIONNAIRE,
  payload,
});

export const getQuestionnaireSuccess = (payload: IQuestionnaireResponse):
{ type: string, payload: IQuestionnaireResponse } => ({
  type: QuestionnaireTypes.GET_QUESTIONNAIRE_SUCCESS,
  payload,
});

export const getQuestionnaireFailure = (payload: any): { type: string, payload: any } => ({
  type: QuestionnaireTypes.GET_QUESTIONNAIRE_FAILURE,
  payload,
});

export const getQuestions = (payload: string): { type: string, payload: string } => ({
  type: QuestionnaireTypes.GET_QUESTIONS,
  payload,
});

export const getQuestionsSuccess = (payload: IQuestionsResponse):
{ type: string, payload: IQuestionsResponse } => ({
  type: QuestionnaireTypes.GET_QUESTIONS_SUCCESS,
  payload,
});

export const getQuestionsFailure = (payload: any): { type: string, payload: any } => ({
  type: QuestionnaireTypes.GET_QUESTIONS_FAILURE,
  payload,
});

export const getAllQuestionnaire = (questionnaireListPageCount: number): {
  type: string,
  payload: any,
 } => ({
  type: QuestionnaireTypes.GET_QUESTIONNAIRE_LIST,
  payload: { questionnaireListPageCount },
});

export const getAllQuestionnaireSuccess = (payload: IAllQuestionnaire):
{ type: string, payload: IAllQuestionnaire } => ({
  type: QuestionnaireTypes.GET_QUESTIONNAIRE_LIST_SUCCESS,
  payload,
});

export const getAllQuestionnaireFailure = (payload: any):
{ type: string, payload: any } => ({
  type: QuestionnaireTypes.GET_QUESTIONNAIRE_LIST_FAILURE,
  payload: { ...payload, getAllQuestionnaire: 0 },
});

export const createQuestion = (payload: any): { type: string, payload: any } => ({
  type: QuestionnaireTypes.CREATE_QUESTIONS,
  payload,
});

export const createQuestionSuccess = (payload: IQuestion):
{ type: string, payload: IQuestion } => ({
  type: QuestionnaireTypes.CREATE_QUESTIONS_SUCCESS,
  payload,
});

export const createQuestionFailure = (payload: any): { type: string, payload: any } => ({
  type: QuestionnaireTypes.CREATE_QUESTIONS_FAILURE,
  payload,
});

export const resetQuestionnaire = (): { type: string } => ({
  type: QuestionnaireTypes.RESET,
});
