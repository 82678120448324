import { SignInActionTypes } from './actions';

interface IUserState {
  loading: boolean | null;
}

const initialState: IUserState = {
  loading: null,
};

export const userReducer = (state = initialState, action: { type: string, payload?: any }) => {
  switch (action.type) {
    case SignInActionTypes.SIGN_IN:
      return {
        ...state,
        loading: true,
      };
    case SignInActionTypes.ADD_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SignInActionTypes.SIGN_IN_SUCCESS:
    case SignInActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
