export const KEYCODE = {
  enterKeyCode: 13,
};

export const UI_CONFIG = {
  loaderSize: 24,
  uploadLoaderSize: 24,
};

export const FILE_UPLOAD = {
  fileSize: 1000000,
  fileType: [
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/excel',
    '.xls',
  ],
};

export const CONSTANT_VALUE = {
  defaultLength: 1,
  zeroIndex: 0,
  defaultRatingScale: 5,
};

export const timezone = {
  id: 194,
  countryCode: 'IN',
  countryName: 'India',
  zoneName: 'Asia/Kolkata',
  gmtOffset: 19800,
  daylightSavingTime: false,
  displayName: '(GMT+05:30) Asia/Kolkata',
};

export const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

export const mailformat = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/;

export const URL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-]*)?\??(?:[\-\+=&;%@\.\w]*)#?(?:[\.\!\/\\\w]*))?)/;

export const API_ERROR_MESSAGE = {
  wrongEmail: 'ERR_RESOURCE_NOT_FOUND',
  wrongPasssword: 'ERR_INCORRECT_PASSWORD',
  notAuthorized: 'You are not authorized',
  noPermissions: 'You don\'t have permissions',
  notAdmin: 'You are not an Admin',
  downloadFailed: 'Download failed, please try again later',
};

export const currencies = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'INR',
    label: 'INR',
  },
];

export const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 57,
    minHeight: 57,
  }),
};

export const QUESTION_TYPE = {
  SINGLE_SELECT: 'singleSelect',
  OPEN_ENDED: 'openEnded',
  RATING: 'rating',
};

export const COMMENT_TYPE = {
  ANSWER: 'ANSWER',
  COMMENT: 'COMMENT',
};
