import { IApiClientErrorDetails } from '../network/apiClientError';

export const REQUEST_FAILED_ERROR = 'REQUEST_FAILED_ERROR';
export const CLEAR_REQUEST_FAILED_ERROR = 'CLEAR_REQUEST_FAILED_ERROR';

interface IAction {
  type: string;
  payload: IApiClientErrorDetails;
}

interface IErrorState {
  error: IApiClientErrorDetails | null;
}

const initialState: IErrorState = {
  error: null,
};

export default function errorHandlingReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case REQUEST_FAILED_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_REQUEST_FAILED_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
