import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  CommonModal as Modal, FullSpinner, Alert,
  // BulkAction,
} from '../components/Common';

// Blurs the childrens in the body
export const blurChildren = (body, blur) => {
  body.childNodes.forEach((c) => {
    if (!(c instanceof HTMLElement)) return;
    /* eslint no-param-reassign: ["error",
    { "props": true, "ignorePropertyModificationsFor": ["c"] }] */
    c.style.filter = blur ? 'blur(3px)' : '';
  });
};

// Utility function for rendering modal component with asyn behaviour
export const showDialogAsync = async ({
  nodeFn,
  doBlur,
  modalId = '',
  parentId = '',
}) => {
  let removeSelf = (x) => x;
  let ele = document.body;
  if (parentId) {
    ele = document.getElementById(parentId);
  }
  const promise = new Promise((resolve, reject) => {
    if (doBlur) {
      blurChildren(ele, doBlur);
    }
    const container = ele.appendChild(document.createElement('div'));
    if (modalId) container.id = modalId;
    removeSelf = () => {
      ReactDOM.unmountComponentAtNode(container);

      if (doBlur) {
        blurChildren(ele, false);
      }

      container.remove();
    };
    ReactDOM.render(nodeFn(resolve, reject), container);
  });
  promise.then(removeSelf, removeSelf);
  return promise;
};

// TODO: Change the params function to object to avoid passing unnecessary
// props to modal component
// utility function to display differnet components in a modal
export const showModal = async (
  {
    node,
    modalId = '',
    className = '',
    closeButtonName = '', // 'close' to make close icon visible
    extraClass = '',
    beforeClose = () => { },
    title = '',
    autoCloseModal = false,
  },
) => showDialogAsync({
  nodeFn: (resolve, reject) => (
    <Modal
      autoCloseModal={autoCloseModal}
      onConfirm={async () => {
        /* eslint no-unused-expressions: 0 */
        resolve();
        beforeClose && (await beforeClose());
      }}
      modalId={modalId}
      onCancel={reject}
      isOpen
      className={className}
      closeButtonName={closeButtonName}
      extraClass={extraClass}
      title={title}
    >
      {node}
    </Modal>
  ),
  doBlur: true,
});

// Utility function to render full-screen spinner
export const showFullScreenSpinner = async (fn, parentId = '') => showDialogAsync(
  {
    nodeFn: (resolve, reject) => (
      <FullSpinner
        functionReturningPromise={fn}
        onResolve={resolve}
        onReject={reject}
      />
    ),
    doBlur: true,
    parentId,
  },
);

export const triggerCloseModal = () => {
  const el = document.getElementById('close-custom-modal');
  if (el) {
    el.click();
  }
};

export const showAlertModal = async (
  {
    type = 'confirmation',
    title = 'Alert',
    primaryText = '',
    secondaryText = '',
    onYes = null,
    onNo = null,
    secondaryContext = '',
    primaryContext = '',
    customClass = '',
  },
) => showModal({
  node: <Alert
    type={type}
    message={{ primaryText, secondaryText }}
    secondaryContext={secondaryContext}
    primaryContext={primaryContext}
    onNo={onNo}
    onYes={onYes}
  />,
  modalId: 'alert-modal',
  extraClass: `custom-modal--alert ${customClass}`,
  title,
});
