/* eslint-disable */
// TODO: Interface are getting use but getting no-unused-vars lint error
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import { IQuestionnaireResponse } from '../../../api/models/Questionnaire';
import './index.scss';
import { ROUTE_NAME, LABEL } from '../../../constants/UserConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    width: '100%',
    margin: '8px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  }
}));

export default function FormReviewCard(props: IQuestionnaireResponse) {
  const {
    id, title, description, isPublished
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const navigateToFormView = () => {
    history.push(`${ROUTE_NAME.mainRoute}/${id}`);
  };
  const text = isPublished ? LABEL.published : LABEL.draft;
  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
      />
      {description && (
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      )}
      <CardActions>
        <Button size="small" color="primary" onClick={navigateToFormView}>
          View Form
        </Button>
      <span className="badge ml-auto">{text}</span>
      </CardActions>
    </Card>
  );
}
