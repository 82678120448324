import React, { memo, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid, Typography, makeStyles,
  Divider, List, ListSubheader, ListItem,
  ListItemSecondaryAction, CircularProgress, Button, TextField, Chip,
} from '@material-ui/core';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import CustomizedTooltips from '../Common/Tooltip';
import { FormLayout } from '../Common';
import './index.scss';
import { QuestionnaireRepository } from '../../api/repositories/Questionnaire';
import { QUESTION_TYPE, UI_CONFIG } from '../../constants/Constants';
import Error from '../Common/Error';
import { ROUTE_NAME, BUTTON } from '../../constants/UserConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '24px',
  },
}));

function OptionList(props) {
  const { options } = props;
  const classes = useStyles();

  return (
    <List
      subheader={(
        <ListSubheader style={{
          lineHeight: 'inherit',
        }}
        >
          Options
        </ListSubheader>
      )}
      className={classes.root}
    >
      {Array.isArray(options) && options.map((option, index) => {
        const { option: optionText, weightage } = option;
        return (
          <ListItem alignItems="center" key={`question-option-${index}`}>
            <Typography style={{
              paddingRight: '8px',
            }}
            >
              {`${index + 1}.`}
            </Typography>
            <Typography className="option-text">
              {optionText}
            </Typography>
            <ListItemSecondaryAction>
              <Typography
                style={{
                  maxWidth: '100px',
                  fontFamily: 'Frank Ruhl Libre',
                }}
                className="ellipsis"
              >
                {weightage}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

const FormHeader = (props) => {
  const { data, onFormEdit, languages } = props;
  const {
    title, description, questionnaireUrl, isPublished, languageCode,
  } = data;
  const selectedLanguage = languages && languages.length
    && languages.find((language) => language.languageCode === languageCode);
  return (
    <FormLayout>
      <div className="d-flex align-center">
        <Typography
          className="title"
          style={{
            width: '90%',
            fontSize: '32px',
            fontWeight: 500,
            paddingBottom: '8px',
          }}
        >
          {title}
        </Typography>
        {!isPublished
          && (
            <Button size="small" color="primary" onClick={onFormEdit}>
              {BUTTON.edit}
            </Button>
          )}
      </div>
      <Typography
        className="description"
        style={{
          width: '100%',
          fontFamily: 'Frank Ruhl Libre !important',
        }}
      >
        {description}
      </Typography>
      <Typography
        className="languageCode"
        style={{
          width: '100%',
          fontFamily: 'Frank Ruhl Libre !important',
          paddingTop: '8px',
        }}
      >
        {`Language: ${selectedLanguage && selectedLanguage.name}`}
      </Typography>
      {questionnaireUrl
        && (
          <div className="urlCont">
            <span className="label">Shareable URL:</span>
            <span className="url">{questionnaireUrl}</span>
          </div>
        )}
    </FormLayout>
  );
};

const QuestionSet = (props) => {
  const {
    questionObj, index, onChange,
  } = props;
  const {
    questionText, options, description, type, isRequired, tags,
  } = questionObj;

  const onQuestionChange = (e) => {
    const { name, value } = e;
    const newQuestionObj = {
      ...questionObj,
      [name]: value,
    };
    onChange(index, { ...newQuestionObj });
  };
  const renderStar = (starLength) => {
    const fields = [];
    for (let index = 0; index < starLength; index += 1) {
      fields.push(
        <div className="star-rating">
          <span className="star-rating-count">{index + 1}</span>
        </div>,
      );
    }
    return fields;
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          container
          wrap="nowrap"
          style={{
            alignItems: 'center',
            paddingBottom: '0px',
            marginTop: '20px',
          }}
          spacing={2}
          item
          xs={12}
          md={12}
        >
          <Grid item className="questIndex_container">
            {isRequired && <Typography className="questRequired">*</Typography>}
            <Typography className="questIndex">{`${index + 1}.`}</Typography>
          </Grid>
          <Grid item xs>
            <Typography
              required={isRequired}
              className="questionText"
              style={{ width: '100%', fontFamily: 'Frank Ruhl Libre' }}
            >
              {questionText}
            </Typography>
          </Grid>
          {description
            && (
              <CustomizedTooltips
                Icon={HelpOutlineOutlined}
                text={description}
              />
            )}
        </Grid>
        {/* keywords section */}
        {tags && tags?.private && tags?.private?.keywords
          && (
            <div className="tags_view_list">
              {tags?.private?.keywords?.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  style={{ margin: '4px' }}
                />
              ))}
            </div>
          )}

        <Grid item xs={12} md={12}>
          {questionObj?.imageUrl && (
            <img className="question-image" src={questionObj?.imageUrl} alt="question" />
          )}
        </Grid>

        <Grid item xs={12} md={12}>
          {type === QUESTION_TYPE.SINGLE_SELECT && (
            <OptionList options={options} onChange={onQuestionChange} />
          )}

          {questionObj?.commentType === 'COMMENT' && (
            <TextField
              style={{
                width: '95%',
                paddingBottom: '8px',
                marginLeft: '2rem',
              }}
              id="outlined-textarea"
              label={questionObj?.comment?.label}
              value={questionObj?.comment?.placeholderText}
              multiline
              variant="outlined"
              name="questionText"
              required={questionObj?.comment?.isRequired}
              disabled
            />
          )}

          {type === QUESTION_TYPE.OPEN_ENDED && (
            <div className="question-type question-type-view">
              <hr className="dashed-line" />
            </div>
          )}

          {type === QUESTION_TYPE.RATING && (
            <div className="question-type question-type-view question-type-rating">
              <div className="start-container">
                {renderStar(options?.length)}
              </div>
            </div>
          )}
        </Grid>

      </Grid>
      <Divider
        variant="middle"
        style={{
          marginTop: '10px',
        }}
      />
    </>
  );
};

const FormView = (props) => {
  const { getQuestionnaire, getQuestions, resetQuestionnaire } = props;
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [questions, setAllQuestions] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    (async function () {
      if (params && params.id) {
        try {
          QuestionnaireRepository.getLanguages()
            .then((response) => {
              const allLanguages = response;
              setLanguages(allLanguages);
            })
            .catch((err) => {
              /* eslint-disable-next-line */
              console.log(err.message);
            });
          const questionnaireResp = await QuestionnaireRepository.getQuestionnaireById(params.id);
          if (questionnaireResp && questionnaireResp.questionnaire) {
            setQuestionnaire({ ...questionnaireResp.questionnaire });
          }
          const allQuestionsResp = await QuestionnaireRepository.getAllQuestions(params.id);
          if (allQuestionsResp && allQuestionsResp.questions) {
            setAllQuestions([...allQuestionsResp.questions]);
          }
          setLoading(false);
        } catch (e) {
          setError(e.message);
          setLoading(false);
        }
      } else {
        history.push(ROUTE_NAME.mainRoute);
      }
    }());
  }, [params, history]);

  const onFormEdit = () => {
    if (params && params.id) {
      resetQuestionnaire();
      const { id } = params;
      getQuestionnaire({ questionnaire: { id } });
      getQuestions({ id });
      history.push(`${ROUTE_NAME.mainRoute}/edit`);
    }
  };

  return (
    loading ? (
      <div className="loaderCont">
        <CircularProgress size={UI_CONFIG.loaderSize} />
      </div>
    )
      : error !== null || true
        ? (
          <div className="newQuestCont">
            <div className="formSect">
              <FormHeader data={questionnaire} onFormEdit={onFormEdit} languages={languages} />
            </div>
            <div className="formSect">
              <FormLayout>
                <Typography>Questions</Typography>
                {
                  Array.isArray(questions) && questions.map((d, index) => (
                    <QuestionSet
                      selected
                      key={`question-set-${index}`}
                      questionObj={d}
                      index={index}
                    />
                  ))
                }
              </FormLayout>
            </div>
          </div>
        )
        : <Error history={history} />
  );
};

export default memo(FormView);
