/* eslint-disable */
// TODO: Interface are getting use but getting no-unused-vars lint error
import { IApiClient } from '../../network/interfaces';
import { ApiClient } from '../../network/client';
import { IQuestionnaireRepository, ILanguage } from './interfaces';
import * as I from '../models/Questionnaire';
import Axios from 'axios';

const ENDPOINTS = {
  getAllQuestionnaire: (page: number) => `/api/v1/questionnaireservice/admin/questionnaires?page=${page}&limit=${9}`,
  getQuestionnaireById: (id: string) => `/api/v1/questionnaireservice/admin/questionnaires/${id}`,
  createQuestionnaire: () => '/api/v1/questionnaireservice/admin/questionnaires',
  putQuestionnaire: (id: string) => `/api/v1/questionnaireservice/admin/questionnaires/${id}`,
  createQuestion: () => '/api/v1/questionnaireservice/admin/questions',
  putQuestion: (id: string) => `/api/v1/questionnaireservice/admin/questions/${id}`,
  getAllQuestions: (id: string) => `/api/v1/questionnaireservice/admin/questions?questionnaireId=${id}&limit=1000`,
  deleteQuestion: (id: string) => `/api/v1/questionnaireservice/admin/questions/${id}`,
  publishQuestionnaire: (id: string) => `/api/v1/questionnaireservice/admin/questionnaires/${id}/publish`,
  getLanguages: () => `/api/v1/questionnaireservice/languages`,
  uploadImage: ()=> `/api/v1/files/questionnaire_images`
};

class QuestionnaireRepository implements IQuestionnaireRepository {
    private apiClient: IApiClient;

    constructor(apiClient: IApiClient) {
      this.apiClient = apiClient;
    }

    public createQuestionnaire = async (payload: I.IQuestionnairePayload):
    Promise<I.IQuestionnaireById> => {
      try {
        const response = await this.apiClient.post(ENDPOINTS.createQuestionnaire(), payload);
        return response;
      } catch (error) {
        return error;
      }
    }

    public getAllQuestionnaire = async (page: number): Promise<I.IAllQuestionnaire> => {
      try {
        const response = await this.apiClient.get(ENDPOINTS.getAllQuestionnaire(page));
        return response;
      } catch (error) {
        return error;
      }
    }

    public getQuestionnaireById = async (id: string): Promise<I.IQuestionnaireById> => {
      try {
        const response = await this.apiClient.get(ENDPOINTS.getQuestionnaireById(id));
        return response;
      } catch (error) {
        return error;
      }
    }

    public putQuestionnaire = async (id: string, payload:
      I.IQuestionnairePayload): Promise<I.IQuestionnaireById> => {
      try {
        const response = await this.apiClient
          .patch(ENDPOINTS.putQuestionnaire(id), payload);
        return response;
      } catch (error) {
        return error;
      }
    }

    public createQuestion = async (payload: I.IQuestionPayload):
      Promise<{question : I.IQuestionPayload}> => {
      try {
        const response = await this.apiClient
          .post(ENDPOINTS.createQuestion(), payload);
        return response;
      } catch (error) {
        return error;
      }
    }

    public deleteQuestion = async (id: string):
    Promise<{}> => { //need to check on return type
      try {
        const response = await this.apiClient.delete(ENDPOINTS.deleteQuestion(id));
        return response;
      } catch (error) {
        return error;
      }
    }

    public putQuestion = async (id: string, payload: I.IQuestionPayload):
    Promise<{question : I.IQuestionPayload}> => {
      try {
        const response = await this.apiClient.put(ENDPOINTS.putQuestion(id), payload);
        return response;
      } catch (error) {
        return error;
      }
    }

    public getAllQuestions = async (id: string): Promise<I.IAllQuestions> => {
      try {
        const response = await this.apiClient.get(ENDPOINTS.getAllQuestions(id));
        return response;
      } catch (error) {
        return error;
      }
    }

    public publishQuestionnaire = async (id: string): Promise<any> => {
      try {
        const response = await this.apiClient.put(ENDPOINTS.publishQuestionnaire(id));
        return response;
      } catch (error) {
        return error;
      }
    }

    public getLanguages = async (): Promise<ILanguage[]> => {
      try {
        const response = await this.apiClient.get(ENDPOINTS.getLanguages());
        return response.languages;
      } catch (error) {
        return error;
      }
    }

    public uploadImage = async (image:any): Promise<any> => {
      const params = {
        include: "publicUrl",
        thumbnailAspectRatio: 1
      }
      try {
        const response = await Axios.post("https://devapi.nen.wfglobal.org/api/v1/files/questionnaire_images",image  ,{params})
        return response?.data?.data;
      } catch (error) {
        return error;
      }
    }
}

const questionnaireRepository = new QuestionnaireRepository(ApiClient);
export { questionnaireRepository as QuestionnaireRepository };
