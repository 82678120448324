import React, { Suspense } from 'react';
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, CssBaseline, Typography } from '@material-ui/core';
import ProtectedRoute from './ProtectedRoutes';
import { Login, Questionnaire } from '../container';
import { ErrorPage } from '../components/Common';

export class Navigation extends React.PureComponent<any, any> {
  public render() {
    const error = (props: any) => <ErrorPage {...props} homepage />;
    const path = () => (<Redirect to="/admin" />);
    return (
      <Suspense fallback={<CircularProgress />}>
        <Switch>
          <Route path="/" exact render={path} />
          <Route path="/admin" exact component={Login} />
          <ProtectedRoute path="/admin/questionnaire" component={Questionnaire} />
          <Route path="*" exact render={error} />
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(Navigation);
