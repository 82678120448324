import React, { PureComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from './store';
import Navigation from './navigation/Navigation';
import './App.scss';

const history = createBrowserHistory();

export default class App extends PureComponent {
  public render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Navigation history={history} />
        </BrowserRouter>
      </Provider>
    );
  }
}
