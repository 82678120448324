import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { UserRepository } from '../../api/repositories/UserRepository';
import {
  addUserData, signInSuccess, SignInActionTypes, addRequestFailedError, signInFailure,
} from './actions';
import { LocalStorage } from '../../utils/LocalStorage';
import {
  AUTH_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY, USER_ID, SUPER_ADMIN,
} from '../../network/constants';
import { IUserModel } from '../../api/models/User';
import { API_ERROR_MESSAGE } from '../../constants/Constants';

interface ISignInAction {
    type: string;
    payload: {
        email: string,
        password: string,
    };
}

export function* signInJob(signInAction: ISignInAction): any {
  const response: IUserModel = yield call(UserRepository.login,
    { user: { ...signInAction.payload } });
  if (response && response.user && response.user.id) {
    const isAdmin = true;
    const isSuperAdmin = true;
    if (isAdmin || isSuperAdmin) {
      yield put(addUserData(response));
      yield LocalStorage.set(AUTH_TOKEN_STORAGE_KEY, response.authToken.jwtToken);
      yield LocalStorage.set(REFRESH_TOKEN_STORAGE_KEY, response.authToken.refreshToken);
      yield LocalStorage.set(USER_ID, response.user.id);
      yield LocalStorage.set(SUPER_ADMIN, isSuperAdmin);
      yield put(signInSuccess());
    } else {
      const errorMessage = { details: { message: API_ERROR_MESSAGE.notAdmin } };
      yield put(signInFailure());
      yield put(addRequestFailedError(errorMessage));
    }
  } else {
    yield put(signInFailure());
    yield put(addRequestFailedError(response));
  }
}

export function* watchSignInActions(): any {
  yield takeEvery(SignInActionTypes.SIGN_IN, signInJob);
}
