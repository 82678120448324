/* eslint-disable */
// TODO: Interface are getting use but getting no-unused-vars lint error
import { IUserModel } from '../../api/models/User';
import { REQUEST_FAILED_ERROR, CLEAR_REQUEST_FAILED_ERROR } from '../../store/errorHandlingReducer';

export const SignInActionTypes = {
  ADD_USER_DATA: 'ADD_USER_DATA',
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
};

interface IAction {
  type: string;
  payload: string;
}

interface IUser {
  email: string;
  password: string;
}

export const signIn = (userData: IUser): { type: string, payload: IUser } => ({
  type: SignInActionTypes.SIGN_IN,
  payload: userData,
});

export const signInSuccess = (): { type: string } => ({
  type: SignInActionTypes.SIGN_IN_SUCCESS,
});

export const addUserData = (userData: IUserModel): { type: string, payload: IUserModel } => ({
  type: SignInActionTypes.ADD_USER_DATA,
  payload: userData,
});
export const addRequestFailedError = (error: any): IAction => ({
  type: REQUEST_FAILED_ERROR,
  payload: error,
});

export const signInFailure = (): {type: string} => ({
  type: SignInActionTypes.SIGN_IN_FAILURE,
});

export const removeError = (): {type: string} => ({
  type: CLEAR_REQUEST_FAILED_ERROR,
});
