import React, { memo } from 'react';
import Paper from '@material-ui/core/Paper';
import './index.scss';

function FormLayout(props: any) {
  const { children } = props;
  return (
    <Paper className="formLayout">
      {
                React.Children.map(children, (child) => (child ? React.cloneElement(child) : null))
            }
    </Paper>
  );
}

FormLayout.propTypes = {

};

export default memo(FormLayout);
