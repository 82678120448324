/* eslint-disable */
import React, {
  PureComponent, memo, useState, useEffect, useRef,
} from 'react';
import {
  TextField, Grid, Typography, Toolbar, Button, Divider, CircularProgress, NativeSelect, InputLabel, Switch, Chip,
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormLayout } from '../Common';
import OptionList from './OptionList';
import { BUTTON, ROUTE_NAME } from '../../constants/UserConstants';
import {
  UI_CONFIG, QUESTION_TYPE, COMMENT_TYPE,
} from '../../constants/Constants';
import { QuestionnaireRepository } from '../../api/repositories/Questionnaire';
import { showAlertModal, showFullScreenSpinner } from '../../utils/Modal';
import { textValidator } from '../../utils/Validator';
import { isObjectChanged } from '../../utils/ObjectHelper';
import {
  createQuestionnaire, getQuestions,
  putQuestionnaire,
  getAllQuestionnaire,
} from '../../container/Questionnaire/actions';
import './index.scss';
import { IQuestionPayload, IOptions } from '../../api/models/Questionnaire';
import { ILanguage } from '../../api/repositories/interfaces';

const FormHeader = memo((props: any) => {
  const {
    data, onChange, isValidTitle, isValidDesc, isValidLang, languages,
  } = props;
  const { title, description, languageCode } = data;
  const renderOption = (language: ILanguage) => (
    <option value={language.languageCode} key={language.id}>
      {' '}
      {language.name}
      {' '}
    </option>
  );
  return (
    <FormLayout>
      <TextField
        className="field"
        name="title"
        type="text"
        id="title"
        onChange={onChange}
        value={title}
        label="Title"
        error={false}
        helperText=""
        fullWidth
      />
      {(!isValidTitle)
        ? <span className="helperText danger">Please enter valid Title</span>
        : null}
      <TextField
        className="field top-margin"
        name="description"
        onChange={onChange}
        type="text"
        id="description"
        label="Description"
        value={description}
        error={false}
        helperText=""
        fullWidth
      />
      {(!isValidDesc)
        ? <span className="helperText danger">Please enter valid Description</span>
        : null}
      <InputLabel
        htmlFor="language-select"
        className="field top-margin"
      >
        Select Language
        <NativeSelect
          className="select-language"
          onChange={onChange}
          value={languageCode}
          name="languageCode"
        >
          <option value="">Select</option>
          {languages && languages.length
            && languages.map((lang: ILanguage) => renderOption(lang))}
        </NativeSelect>
      </InputLabel>
      {(!isValidLang)
        ? <span className="helperText danger">Please select valid language</span>
        : null}
    </FormLayout>
  );
});

const QuestionSet = memo((props: any) => {
  const {
    selected, questionObj, index, onDeleteQuestion,
    onChange, onHighlight, toolbar, onNewQuestion,
    showLoader,
  } = props;
  const [questionState, setQuestionState] = useState({
    questionText: '',
    description: '',
    imageUrl: '',
    counter: 0,
    isRequired: true,
    options: [{
      option: '',
      value: 0,
      weightage: 0,
    }],
    id: '',
    type: '',
    commentType: null,
    comment: undefined as unknown as {},
    edited: '',
    tags: undefined as unknown as {} | any,
  });

  const [otherOptionObj, setOtherOptionObj] = useState({
    label: '',
    placeHolder: '',
    isRequired: '',
    isOther: false,
  });

  const [error, setError] = useState('');
  const [starScaleValue, setStarScaleValue] = useState(0);

  const [chips, setChips] = useState<string[]>([]);
  const [newChip, setNewChip] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (questionObj) {
      setQuestionState(JSON.parse(JSON.stringify(questionObj)));
      setChips(questionObj?.tags?.private?.keywords);
    }
  }, [questionObj]);

  const onQuestionChange = (e: any) => {
    let { value } = e;
    const { name } = e;
    if (name === 'questionText' || name === 'description') {
      value = textValidator(value);
    }
    if (name === 'isRequired') {
      value = value === 'false';
    }
    const newQuestionObj = {
      ...questionState,
      [name]: value,
    };
    setQuestionState(newQuestionObj);
  };

  const onChipsChange = (chips: string[]) => {
    const newQuestionObj = {
      ...questionState,
      tags: {
        private: {
          keywords: chips,
        },
      },
    };
    setQuestionState(newQuestionObj);
  }

  const setOtherOptionData = (data: any) => {
    const newOtherOptionObj = {
      ...otherOptionObj,
      label: data?.option || '',
      isOther: data?.isOther || false,
      placeHolder: data?.placeholderText || '',
      isRequired: data?.isRequired || false,
    };
    setOtherOptionObj(newOtherOptionObj);
  };
  const onOtherOptionChange = (e: any) => {
    const { value } = e;
    const { name } = e;
    const newOtherOptionObj = {
      ...otherOptionObj,
      [name]: value,
    };
    setOtherOptionObj(newOtherOptionObj);
    questionState.edited = 'Yes';
  };

  const {
    questionText, options, description, type, isRequired, tags,
  } = questionState;

  const tagList = tags?.private?.keywords || [];

  const isValid = () => {
    let valid = false;
    switch (type) {
      case QUESTION_TYPE.SINGLE_SELECT:
        if (questionText && options.length) {
          const isOptionsValid = options.filter((d: any) => d.option !== '');
          if (isOptionsValid.length >= 2) {
            setError('');
            valid = true;
          }
        }
        if (!valid) {
          setError('Question and options are mandatory fields');
        }
        break;
      default:
        if (QUESTION_TYPE.RATING === type && !(starScaleValue >= 1 && starScaleValue <= 10)) {
          valid = false;
        } else if (questionText && type) {
          setError('');
          valid = true;
        } else {
          valid = false;
          setError('Question and type are mandatory fields');
        }
    }
    return valid;
  };

  const onClickSection = () => {
    onHighlight(index);
  };

  const onCancel = () => {
    setQuestionState(JSON.parse(JSON.stringify(questionObj)));
  };

  const onDelete = async () => {
    await onDeleteQuestion(questionState);
  };

  const isChanged = () => JSON.stringify(questionObj) !== JSON.stringify(questionState);

  const removeOtherOption = async () => {
    questionState.comment = null as unknown as {};
    const filteredOption = questionState?.options?.filter((item: any) => item?.isOther !== true);
    questionState.options = filteredOption;
  };
  const updateStateWithOtherOption = async () => {
    await removeOtherOption();
    if (questionState.commentType === COMMENT_TYPE.ANSWER) {
      const otherObj = {
        weightage: 0,
        isOther: true,
        value: questionState.options.length + 1,
        placeholderText: otherOptionObj.placeHolder,
        option: otherOptionObj.label,
      };
      questionState.options.push(otherObj);
    } else if (questionState.commentType === COMMENT_TYPE.COMMENT) {
      const comment = {
        placeholderText: otherOptionObj.placeHolder,
        label: otherOptionObj.label,
        isRequired: otherOptionObj.isRequired,
      };
      questionState.comment = comment;
    }
  };

  const onSave = async () => {
    if (isValid() && isChanged()) {
      if (otherOptionObj.isOther) {
        await updateStateWithOtherOption();
      } else if (!otherOptionObj?.isOther) {
        await removeOtherOption();
      }

      await onChange(index, { ...questionState }, false);
    }
  };

  const onNextQuestionChild = async () => {
    if (isValid() && isChanged()) {
      if (otherOptionObj.isOther) {
        await updateStateWithOtherOption();
      }
      await onChange(index, { ...questionState }, true);
    } else if (!isChanged() && questionState.id) {
      onNewQuestion();
    }
  };

  const setQuestionType = (questionType: string) => {
    const newQuestionObj = { ...questionState, type: questionType };
    // add the first default option when the question type is changed from open ended/rating
    if (questionType === QUESTION_TYPE.SINGLE_SELECT) {
      newQuestionObj.options = [{
        option: '',
        value: 0,
        weightage: 0,
      }];
    }
    setError('');
    setQuestionState(newQuestionObj);
  };

  const onQuestionTypeChange = (e: any) => {
    const questionType = e.target.value;
    const questionTypeDisplayedName = e.target.options[e.target.selectedIndex].text;
    // Do not show confirmation modal if question type is selected for the first time
    if (type) {
      showAlertModal({
        type: 'confirmation',
        primaryContext: `Are you sure you want to change the question type to \
        <br><b style="line-height: calc(1em * 2)">${questionTypeDisplayedName}`,
        title: 'Confirmation',
        onYes: () => setQuestionType(questionType),
        customClass: 'publishModal',
      });
    } else {
      setQuestionType(questionType);
    }
  };

  const renderStar = () => {
    const fields: JSX.Element[] = [];
    for (let index = 0; index < starScaleValue; index += 1) {
      fields.push(
        <div className="star-rating">
          <span className="star-rating-count">{index + 1}</span>
        </div>,
      );
    }
    return fields;
  };

  const onChangeRatingInput = (e: any) => {
    const data = e.target.value as unknown as number;
    const truncatedData = Math.trunc(data);
    let count = 0;
    if (truncatedData > 10) {
      count = 10;
      setStarScaleValue(count);
      questionState.counter = count;
    } else if (Number(truncatedData) < 1) {
      count = '' as unknown as number;
      setStarScaleValue(count);
      questionState.counter = count;
    } else {
      count = truncatedData;
      setStarScaleValue(count);
      questionState.counter = count;
    }
    questionState.edited = 'Yes';
  };

  const onFileSelect = async (e: any) => {
    const data = new FormData();
    data.append('file', e.target.files[0]);
    try {
      const response = await QuestionnaireRepository.uploadImage(data);
      const newQuestionObj = {
        ...questionState,
        imageUrl: response?.publicUrl,
      };
      setQuestionState(newQuestionObj);
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (type === QUESTION_TYPE.RATING) {
      setStarScaleValue(options.length);
      questionState.counter = options.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, type]);

  const handleChipDelete = (chipToDelete: string) => () => {
    const newQuestionObj = {
      ...questionState,
      tags: {
        private: {
          keywords: chips?.filter((chip) => chip !== chipToDelete),
        },
      },
    };
    setQuestionState(newQuestionObj);
    setChips((prevChips) => prevChips?.filter((chip) => chip !== chipToDelete));
  };

  const handleAddChip = () => {
    if (newChip?.trim() !== '') {
      setChips((prevChips) => [...prevChips || [], newChip?.trim()]);
      setNewChip('');
      onChipsChange([...chips || [], newChip?.trim()]);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      handleAddChip();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <div
      role="tab"
      style={{
        outline: 'none',
      }}
      tabIndex={0}
      onKeyDown={onClickSection}
      onClick={onClickSection}
    >
      <Grid container spacing={2}>
        <Grid
          container
          wrap="nowrap"
          spacing={2}
          item
          xs={12}
          md={12}
        >
          <Grid item>
            <Typography className="questIndex">{`${index + 1}.`}</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              style={{
                width: '100%',
                paddingBottom: '8px',
              }}
              id="outlined-textarea"
              placeholder="Enter your question"
              multiline
              variant="outlined"
              name="questionText"
              value={questionText}
              onChange={(e) => onQuestionChange(e.target)}
            />
            <TextField
              style={{
                width: '100%',
              }}
              id="outlined-textarea-2"
              placeholder="Question description"
              rows={2}
              multiline
              variant="outlined"
              name="description"
              value={description}
              onChange={(e) => onQuestionChange(e.target)}
            />
            <div className="required_question">
              <div className="upload-image-container">
                <Typography style={{
                  paddingRight: '8px',
                }}
                >
                  Upload an image:
                </Typography>
                <input
                  accept="image/*"
                  type="file"
                  id="imageUpload"
                  name="imageUpload"
                  onChange={onFileSelect}
                  key=""
                />

              </div>
              <div className="question-required">
                <Typography style={{
                  paddingRight: '8px',
                }}
                >
                  Required:
                </Typography>
                <Switch onClick={(e) => onQuestionChange(e.target)} name="isRequired" value={isRequired} checked={isRequired} className="question-required-switch" />
              </div>
            </div>
            {/* tags field */}
            <div className='chip-container'>
              {chips?.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={handleChipDelete(chip)}
                  style={{ margin: '4px' }}
                />
              ))}
              <TextField
                className="field"
                label="Add Keywords"
                value={newChip}
                onChange={(e) => setNewChip(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAddChip()}
                inputRef={inputRef}
              />
            </div>

            {/* question type dropdown */}
            <InputLabel
              htmlFor="language-select"
              className="select-question-type"
            >
              Select Question Type
              <NativeSelect
                className="select-language"
                onChange={onQuestionTypeChange}
                value={type}
                name="questionType"
              >
                <option value="" disabled>Select</option>
                <option value="singleSelect"> Multiple Choice Question</option>
                <option value="openEnded">Open Ended Question</option>
                <option value="rating">Star Rating Question</option>
              </NativeSelect>
            </InputLabel>

            <Grid item xs={12} md={12}>
              {type === QUESTION_TYPE.SINGLE_SELECT && (
                <OptionList options={options} onChange={onQuestionChange} otherOnchange={onOtherOptionChange} setOtherOptionData={setOtherOptionData} questionState={questionState} />
              )}

              {type === QUESTION_TYPE.RATING && (
                <div className="question-type question-type-edit question-type-rating">
                  <TextField
                    label="Enter Scale you want"
                    placeholder="max-10"
                    type="number"
                    variant="outlined"
                    value={starScaleValue}
                    name="starScaleValue"
                    InputProps={{ inputProps: { min: 1, max: 10 } }}
                    onChange={onChangeRatingInput}
                    style={{
                      maxWidth: '100%',
                    }}
                  />
                  <div className="start-container">
                    {renderStar()}
                  </div>
                </div>
              )}

              {type === QUESTION_TYPE.RATING && (
                <div className="question-type question-type-edit" />
              )}
            </Grid>

          </Grid>
        </Grid>

        {(selected && error)
          ? (
            <Grid item xs={12} md={12} style={{ paddingTop: '0px' }}>
              <span
                className="helperText danger"
                style={{ paddingLeft: '16px' }}
              >
                {error}
              </span>
            </Grid>
          ) : null}
      </Grid>
      <Divider
        variant="middle"
        style={{
          marginTop: '15px',
          marginBottom: '10px',
        }}
      />
      {selected && toolbar({
        showLoader,
        onCancel,
        onSave,
        onDelete,
        error,
        onNewQuestion: onNextQuestionChild,
        questionIndex: index,
      })}
    </div>
  );
});

class NewQuestionnaire extends PureComponent<any, any> {
  initQuestion = {
    questionText: '',
    description: '',
    imageUrl: '',
    counter: 0,
    isRequired: true,
    order: 10,
    isPublished: false,
    type: '',
    options: [{
      option: '',
      value: 0,
      weightage: 0,
    }],
    commentType: null,
    comment: undefined,
    chips: [],
  }

  constructor(props: any) {
    super(props);
    this.state = {
      newQuestionnaire: {
        title: '',
        description: '',
        category: 'scored',
        languageCode: '',
        allowMultipleResponses: true,
        id: '',
        isPublished: false,
        type: '',
        commentType: null,
      },
      languages: null,
      addNewSection: false,
      questionSet: [this.initQuestion],
      selectedQuestion: 0,
      isValidQuestionnaireTitle: true,
      isValidQuestionnaireDesc: true,
      isValidQuestionnaireLang: true,
      showQuestionFields: false,
      showLoader: false,
    };
  }

  componentDidMount() {
    const { questionnaire } = this.props;
    const {
      activeQuestionnaire,
      activeQuestionnaireQuestions,
      loadingQuestions,
    } = questionnaire;

    QuestionnaireRepository
      .getLanguages()
      .then((response) => {
        this.setState({
          languages: response,
        });
      })
      .catch((err) => {
        /* eslint-disable-next-line */
        console.log(err.message);
      });
    if (activeQuestionnaire) {
      const { questionnaire: childQuestionnaire } = activeQuestionnaire;
      const newData = JSON.parse(JSON.stringify(childQuestionnaire));
      if (newData) {
        this.setState({
          newQuestionnaire: newData,
          showQuestionFields: true,
        });
      }
    }
    if (activeQuestionnaireQuestions) {
      const { questions } = activeQuestionnaireQuestions;
      this.setState({
        selectedQuestion: questions.length,
        questionSet: [...questions],
      });
    }
    if (loadingQuestions !== null) {
      this.setState({ showLoaderQuestions: loadingQuestions });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const {
      questionnaire: {
        activeQuestionnaire: oldActiveQuestionnaire,
        activeQuestionnaireQuestions: oldActiveQuestionnaireQuestions,
        loadingQuestions: oldLoadingQuestions,
      },
    } = prevProps;
    const { newQuestionnaire: prevNewQuestionnaire } = prevState;
    const { newQuestionnaire: stateNewQuestionnaire, addNewSection } = this.state;
    const {
      questionnaire: {
        activeQuestionnaire: newActiveQuestionnaire,
        activeQuestionnaireQuestions: newActiveQuestionnaireQuestions,
      },
      loadingQuestions: newLoadingQuestions,
    } = this.props;
    if (isObjectChanged(newLoadingQuestions, oldLoadingQuestions)) {
      this.setStateOnUpdate({ showLoaderQuestions: newLoadingQuestions });
    }
    if (isObjectChanged(prevProps, this.props)) {
      if (newActiveQuestionnaire) {
        const { questionnaire: newQuestionnaire } = newActiveQuestionnaire;
        const { questionnaire = {} } = oldActiveQuestionnaire || {};
        if (isObjectChanged(newQuestionnaire, questionnaire)) {
          this.setStateOnUpdate({
            newQuestionnaire,
            showQuestionFields: true,
          });
        }
      }
      if (isObjectChanged(newActiveQuestionnaireQuestions, oldActiveQuestionnaireQuestions)) {
        const { questions } = newActiveQuestionnaireQuestions;
        const newQuestionSet = addNewSection || questions.length === 0
          ? [...questions, { ...this.initQuestion }]
          : [...questions];
        this.setStateOnUpdate({
          selectedQuestion: newQuestionSet.length - 1,
          questionSet: newQuestionSet,
          addNewSection: false,
        });
      }
    }
    if (JSON.stringify(prevNewQuestionnaire)
      !== JSON.stringify(stateNewQuestionnaire)
    ) {
      const { title, description, languageCode } = stateNewQuestionnaire;
      const { title: oldT, description: oldD, languageCode: oldL } = prevNewQuestionnaire;
      let titleFlag = true;
      let descFlag = true;
      let langFlag = true;
      if (oldT !== title) {
        if (!title) titleFlag = false;
        this.setStateOnUpdate({
          isValidQuestionnaireTitle: titleFlag,
        });
      }
      if (oldD !== description) {
        if (!description) descFlag = false;
        this.setStateOnUpdate({
          isValidQuestionnaireDesc: descFlag,
        });
      }
      if (oldL !== languageCode) {
        if (!languageCode) langFlag = false;
        this.setStateOnUpdate({
          isValidQuestionnaireLang: langFlag,
        });
      }
    }
  }

  setStateOnUpdate = (data: any) => {
    this.setState(data);
  }

  onCreateQuestionnaire = () => {
    const { newQuestionnaire } = this.state;
    const { createQuestionnaireDispatch } = this.props;
    if (newQuestionnaire.title && newQuestionnaire.description) {
      const data = { ...newQuestionnaire };
      delete data.id;
      createQuestionnaireDispatch && createQuestionnaireDispatch(data);
    } else {
      if (!newQuestionnaire.title) this.setState({ isValidQuestionnaireTitle: false });
      if (!newQuestionnaire.description) this.setState({ isValidQuestionnaireDesc: false });
      if (!newQuestionnaire.languageCode) this.setState({ isValidQuestionnaireLang: false });
    }
  };

  onHighlight = (index: number) => {
    const { selectedQuestion } = this.state;
    if (selectedQuestion !== index) {
      this.setState({ selectedQuestion: index });
    }
  };

  onDeleteQuestion = async (data: any) => {
    const { id } = data;
    const { questionSet } = this.state;
    try {
      if (id) {
        await QuestionnaireRepository.deleteQuestion(id);
        const newState = questionSet.filter((question: any) => question.id !== id);
        this.setState({ questionSet: newState });
      } else {
        const newState = questionSet.slice(0, questionSet.length - 1);
        this.setState({ questionSet: newState });
      }
    } catch (error) {

    }
  };

  onChange = async (index: number, data: any, addNewSection = false) => {
    const { newQuestionnaire } = this.state;
    const { getQuestionsDispatch } = this.props;
    this.setState({ showLoader: true });
    try {
      const payload = {
        question: {
          ...data,
          order: 10 * (index + 1),
          questionnaireId: newQuestionnaire.id,
        },
      };
      const formattedPayload = this.formatPayload(payload, data.counter);
      if (data.id) {
        await QuestionnaireRepository.putQuestion(data.id, formattedPayload);
      } else {
        await QuestionnaireRepository.createQuestion(formattedPayload);
      }
      getQuestionsDispatch(newQuestionnaire);
    } catch (error) {
      /* eslint-disable-next-line */
      console.log('error::', error);
    }
    this.setState({ showLoader: false, addNewSection });
  };

  formatPayload = (payload: IQuestionPayload, counter?: number) => {
    switch (payload.question.type) {
      case QUESTION_TYPE.OPEN_ENDED:
        payload.question.options = [];
        break;
      case QUESTION_TYPE.RATING:
        payload.question.options = this.generateOptionsForRatingQuestion(counter || 0);
        break;
      default:
        break;
    }
    return payload;
  }

  generateOptionsForRatingQuestion = (counter: number): IOptions[] => {
    const options = [];
    let index;
    for (index = 1; index <= counter; index += 1) {
      options.push({
        option: `${index} star`,
        value: `${index}`,
        weightage: index,
      });
    }
    return options;
  }

  onNewQuestion = () => {
    const { questionSet } = this.state;
    const newData = [...questionSet, { ...this.initQuestion }];
    this.setState({
      questionSet: newData,
      selectedQuestion: newData.length - 1,
      addNewSection: true,
    });
  };

  onChangeHeaderField = (e: any) => {
    const { name, value } = e.target;
    const { languages } = this.state;
    if (name === 'languageCode') {
      if (value && this.state.newQuestionnaire.languageCode !== '') {
        const language = languages.find((lang: ILanguage) => lang.languageCode === value);
        showAlertModal({
          type: 'confirmation',
          primaryContext: `Are you sure you want to change the language to  \
            <br><b style="line-height: calc(1em * 2)">${language.name}`,
          title: 'Confirmation',
          onYes: () => this.onLanguageChange(name, value),
          customClass: 'publishModal',
        });
      } else {
        this.onLanguageChange(name, value);
      }
    } else {
      const { newQuestionnaire } = this.state;
      this.setState({ newQuestionnaire: { ...newQuestionnaire, [name]: textValidator(value) } });
    }
  };

  getToolbar = (childProps: any) => {
    const {
      onNewQuestion: childOnNewQuestion, onSave, onCancel,
      questionIndex, showLoader, onDelete,
    } = childProps;
    const { questionSet } = this.state;

    return (
      <Toolbar>
        <div>
          {questionIndex === (questionSet.length - 1) && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="button_action"
              startIcon={<AddCircle />}
              onClick={childOnNewQuestion}
              disabled={showLoader}
            >
              {showLoader ? <CircularProgress size={UI_CONFIG.loaderSize} /> : BUTTON.nextQuestion}
            </Button>
          )}
        </div>
        <div className="toolbarRight">
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="button_action disable"
            onClick={onCancel}
            style={{ display: 'none' }}
            disabled={showLoader}
          >
            {showLoader ? <CircularProgress size={UI_CONFIG.loaderSize} /> : BUTTON.clear}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="button_action"
            style={{ marginRight: '10px' }}
            onClick={onDelete}
            disabled={showLoader || !(questionSet.length > 1)}
          >
            {showLoader ? <CircularProgress size={UI_CONFIG.loaderSize} /> : BUTTON.delete}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="button_action"
            onClick={onSave}
            disabled={showLoader}
          >
            {showLoader ? <CircularProgress size={UI_CONFIG.loaderSize} /> : BUTTON.save}
          </Button>
        </div>
      </Toolbar>

    );
  };

  onUpdateQuestionnaire = () => {
    const { questionnaire, putQuestionnaireDispatch } = this.props;
    const { newQuestionnaire } = this.state;
    if (JSON.stringify(questionnaire.activeQuestionnaire.questionnaire)
      !== JSON.stringify(newQuestionnaire)) {
      if (newQuestionnaire.title && newQuestionnaire.description
        && newQuestionnaire.languageCode) {
        putQuestionnaireDispatch && putQuestionnaireDispatch(newQuestionnaire);
      } else {
        if (!newQuestionnaire.title) this.setState({ isValidQuestionnaireTitle: false });
        if (!newQuestionnaire.description) this.setState({ isValidQuestionnaireDesc: false });
        if (!newQuestionnaire.languageCode) this.setState({ isValidQuestionnaireLang: false });
      }
    }
  };

  onLanguageChange = (name: string, value: string) => {
    const { newQuestionnaire } = this.state;
    this.setState({ newQuestionnaire: { ...newQuestionnaire, [name]: textValidator(value) } });
  }

  onSubmitForm = async () => {
    const {
      newQuestionnaire, isValidQuestionnaireDesc, isValidQuestionnaireTitle,
      isValidQuestionnaireLang,
    } = this.state;
    const { getAllQuestionnaireDispatch, history } = this.props;
    const { isPublished: childIsPublished, id } = newQuestionnaire;
    if (isValidQuestionnaireDesc && isValidQuestionnaireTitle
      && isValidQuestionnaireLang && !childIsPublished) {
      this.setState({ showLoader: true });
      try {
        await showFullScreenSpinner(async () => QuestionnaireRepository.publishQuestionnaire(id));
        getAllQuestionnaireDispatch(1);
        history.push(ROUTE_NAME.mainRoute);
      } catch (e) {
        showAlertModal({
          type: 'alert',
          title: 'Error',
          primaryContext: 'Something went wrong will publishing the questionnaire',
          secondaryContext: e.message,
        });
      }
      this.setState({ showLoader: false });
    }
  };

  showConfirmationModal = async () => {
    const { questionSet, newQuestionnaire } = this.state;
    const { questionnaire: { activeQuestionnaire: { questionnaire } } } = this.props;
    const resp = await showFullScreenSpinner(() => QuestionnaireRepository
      .getQuestionnaireById(newQuestionnaire.id));
    if (resp && resp.questionnaire && Array.isArray(questionSet)
      && questionSet.find((d) => d.id)) {
      const { questionnaire: { maxScore } } = resp;
      await showAlertModal({
        type: 'confirmation',
        primaryContext: `Are you sure you want to publish the \
          <br><b style="line-height: calc(1em * 2)">${questionnaire.title}</b><br> \
          questionnaire with max score of <br><b style="line-height: calc(1em * 2)">${maxScore}</b>?`,
        title: 'Confirmation',
        onYes: this.onSubmitForm,
        customClass: 'publishModal',
      });
    } else {
      await showAlertModal({
        type: 'alert',
        primaryContext: 'Questionnaire should contain atleast one question',
        title: 'Alert',
      });
    }
  };

  render() {
    const {
      newQuestionnaire, isValidQuestionnaireDesc,
      isValidQuestionnaireTitle, showQuestionFields,
      questionSet, selectedQuestion, showLoaderQuestions,
      showLoader, isValidQuestionnaireLang, languages,
    } = this.state;
    const { title, description, languageCode } = newQuestionnaire;
    const { questionnaire: { loading } } = this.props;
    return (
      <div className="newQuestCont">
        <div className="formSect">
          <FormHeader
            data={newQuestionnaire}
            onChange={this.onChangeHeaderField}
            isValidDesc={isValidQuestionnaireDesc}
            isValidTitle={isValidQuestionnaireTitle}
            isValidLang={isValidQuestionnaireLang}
            languages={languages}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={(title && description && languageCode) ? 'button_action' : 'button_action.disable'}
            style={{
              margin: '8px auto',
            }}
            onClick={showQuestionFields
              ? this.onUpdateQuestionnaire : this.onCreateQuestionnaire}
            disabled={!(title && description && languageCode)}
          >
            {loading ? <CircularProgress size={UI_CONFIG.loaderSize} />
              : showQuestionFields ? BUTTON.save : BUTTON.createNewQuestionnaire}
          </Button>
        </div>
        {
          showQuestionFields
          && (
            <div className="formSect">
              <FormLayout>
                {
                  Array.isArray(questionSet) && questionSet.map((d, index) => (
                    <QuestionSet
                      selected={selectedQuestion === index}
                      onHighlight={this.onHighlight}
                      key={`question-set-${index}`}
                      questionObj={d}
                      index={index}
                      onChange={this.onChange}
                      onNewQuestion={this.onNewQuestion}
                      onDeleteQuestion={this.onDeleteQuestion}
                      showLoader={showLoader}
                      toolbar={this.getToolbar}
                    />
                  ))
                }
              </FormLayout>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="button_action"
                style={{
                  margin: '8px auto',
                }}
                disabled={!(isValidQuestionnaireTitle && isValidQuestionnaireDesc
                  && isValidQuestionnaireLang)}
                onClick={this.showConfirmationModal}
              >
                {showLoaderQuestions
                  ? <CircularProgress size={UI_CONFIG.loaderSize} />
                  : newQuestionnaire.isPublished ? BUTTON.unpublish : BUTTON.publish}
              </Button>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  ...state,
});

const mapDispatchToProps = (dispatch: any) => ({
  createQuestionnaireDispatch: (payload: any) => dispatch(createQuestionnaire(payload)),
  getQuestionsDispatch: (payload: any) => dispatch(getQuestions(payload)),
  putQuestionnaireDispatch: (payload: any) => dispatch(putQuestionnaire(payload)),
  getAllQuestionnaireDispatch: (pageCount: number) => dispatch(getAllQuestionnaire(pageCount)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewQuestionnaire));
