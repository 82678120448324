import { QuestionnaireTypes } from './actions';
import { IAllQuestionnaire } from '../../api/models/Questionnaire';

interface IQuestionnaireState {
  loading: boolean | null,
  loadingQuestions: boolean | null,
  error: any | null,
  questionnaireList: IAllQuestionnaire | null,
  activeQuestionnaire: any,
  activeQuestionnaireQuestions: any,
  questionnaireListPageCount: number
}

const initialState: IQuestionnaireState = {
  loading: null,
  loadingQuestions: null,
  error: null,
  questionnaireList: null,
  activeQuestionnaire: null,
  activeQuestionnaireQuestions: null,
  questionnaireListPageCount: 0,
};

export const questionnaireReducer = (state = initialState,
  action: {
  type: string, payload?: any
}) => {
  const { type, payload } = action;
  switch (type) {
    case QuestionnaireTypes.CREATE_QUESTIONNAIRE:
      return {
        ...state,
        loading: true,
      };
    case QuestionnaireTypes.CREATE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        activeQuestionnaire: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.CREATE_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        error: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.PUT_QUESTIONNAIRE:
      return {
        ...state,
        loading: true,
      };
    case QuestionnaireTypes.PUT_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        activeQuestionnaire: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.PUT_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        error: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.GET_QUESTIONS:
      return {
        ...state,
        loadingQuestions: true,
      };
    case QuestionnaireTypes.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        activeQuestionnaireQuestions: { ...payload },
        loadingQuestions: false,
      };
    case QuestionnaireTypes.GET_QUESTIONS_FAILURE:
      return {
        ...state,
        error: { ...payload },
        loadingQuestions: false,
      };
    case QuestionnaireTypes.GET_QUESTIONNAIRE:
      return {
        ...state,
        loading: true,
      };
    case QuestionnaireTypes.GET_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        activeQuestionnaire: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.GET_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        error: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.GET_QUESTIONNAIRE_LIST:
      return {
        ...state,
        ...payload,
        loading: true,
      };
    case QuestionnaireTypes.GET_QUESTIONNAIRE_LIST_SUCCESS:
      return {
        ...state,
        questionnaireList: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.GET_QUESTIONNAIRE_LIST_FAILURE:
      return {
        ...state,
        error: { ...payload },
        loading: false,
      };
    case QuestionnaireTypes.RESET:
      return {
        ...state,
        error: null,
        activeQuestionnaire: null,
        activeQuestionnaireQuestions: null,
      };
    default:
      return state;
  }
};

export default questionnaireReducer;
