import React, { memo } from 'react';
import parser from 'html-react-parser';
import { Button } from '@material-ui/core';
import { triggerCloseModal } from '../../../utils/Modal';
import './index.scss';

function Alert({
  type, primaryText = 'Yes', secondaryText = 'No', onNo, onYes, secondaryContext, primaryContext,
}) {
  const getAlertButtons = () => {
    let el = null;
    if (type === 'confirmation') {
      el = (
        <>
          <Button
            type="button"
            onClick={async () => {
              triggerCloseModal();
              onNo && await onNo();
            }}
          >
            {parser(secondaryText)}
          </Button>
          <Button
            type="button"
            className="button__theme"
            onClick={async () => {
              triggerCloseModal();
              onYes && await onYes();
            }}
          >
            {parser(primaryText)}
          </Button>
        </>
      );
    } else {
      el = (
        <Button
          type="button"
          onClick={() => {
            triggerCloseModal();
            onYes && onYes();
          }}
        >
          Ok
        </Button>
      );
    }
    return (
      <div className="buttonSec action-btn">
        {el}
      </div>
    );
  };

  return (
    <div className="alert-container">
      <p className="alert-container__p">{parser(primaryContext)}</p>
      {secondaryContext && (
        <div style={{
          maxHeight: '180px',
          overflowY: 'scroll',
        }}
        >
          {
            typeof secondaryContext === 'object' ? secondaryContext
              : <span className="">{parser(secondaryContext)}</span>
          }
        </div>
      )}
      {getAlertButtons()}
    </div>
  );
}

export default memo(Alert);
