/* eslint-disable */
import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IApiClientErrorDetails } from '../../network/apiClientError';
import { ERROR_MESSAGE, BUTTON, ROUTE_NAME } from '../../constants/UserConstants';
import { UI_CONFIG, KEYCODE, API_ERROR_MESSAGE } from '../../constants/Constants';
import './index.scss';
import { Header } from '../Common/BrandHeader';

interface IState {
  email: string;
  password: string;
  validatedEmail: boolean;
  validatedPassword: boolean;
}

interface IProps {
  signIn: (email: string, password: string) => void;
  error: {
    details: IApiClientErrorDetails,
  };
  loading: boolean;
  location: any;
  removeError: () => void;
  history: any;
}

interface IEvent {
  target: {
    value: string,
  };
}

class Login extends PureComponent<IProps, IState> {
  public state = {
    email: '',
    password: '',
    validatedEmail: true,
    validatedPassword: true,
  };

  public render(): React.ReactNode {
    const { from } = this.props.location;
    const { history } = this.props;
    if (localStorage.getItem('auth_token') !== null) {
      if (from) {
        history.push(from.pathname);
      } else {
        this.props.removeError();
        history.push(ROUTE_NAME.mainRoute);
      }
    }

    function passwordErrorMessage(value: string): string {
      if(value) return ERROR_MESSAGE.validatePassword;
      return ERROR_MESSAGE.emptyPassword;
    }
    return (
      <div className="login-comp">
        <Header />
        <main className="main">
          <Paper className="paper">
            <span>
              <h2 className="header" id="login-heading">Sign In</h2>
              {this.props.error ? (
                <h5 className="error">
                  {this.props.error.details.message === API_ERROR_MESSAGE.wrongEmail
                    ? ERROR_MESSAGE.emailError
                    : this.props.error.details.message === API_ERROR_MESSAGE.wrongPasssword
                      ? ERROR_MESSAGE.passwordError : this.props.error.details.message}
                </h5>
              ) : null}
              <form className="form" onKeyUp={this.handleKeyPressLogin}>
                <TextField
                  className="field"
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onBlur={this.updateEmail}
                  onChange={this.updateEmail}
                  label="Email Address"
                  error={!this.state.validatedEmail}
                  helperText={!this.state.validatedEmail && ERROR_MESSAGE.validateEmail}
                  fullWidth
                />
                <TextField
                  className="field"
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  label="Password"
                  onBlur={this.updatePassword}
                  onChange={this.updatePassword}
                  error={!this.state.validatedPassword}
                  helperText={!this.state.validatedPassword && passwordErrorMessage(this.state.password)}
                  fullWidth
                />
                <Button
                  id="login-button"
                  type="button"
                  fullWidth
                  variant="contained"
                  className="button_action"
                  onClick={this.handleLogin}
                >
                  {this.props.loading ? <CircularProgress size={UI_CONFIG.loaderSize} /> : BUTTON.signIn}
                </Button>
              </form>
            </span>
          </Paper>
        </main>
      </div>
    );
  }

  private handleKeyPressLogin = (event: any): void => {
    event.preventDefault();
    if (event.keyCode === KEYCODE.enterKeyCode) {
      this.handleLogin();
    }
  }

  private handleLogin = (): void => {
    this.setState((state: IState) => ({
      validatedEmail: this.validateEmail(state.email),
      validatedPassword: state.password.length > 7,
    }), () => {
      if (this.state.validatedEmail && this.state.validatedPassword) {
        this.props.signIn(this.state.email, this.state.password);
      }
    });
  }

  private validateEmail = (value: string): boolean => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return reg.test(value);
  }

  private updatePassword = (event: IEvent) => this.setState({
    password: event.target.value,
  })

  private updateEmail = (event: IEvent) => this.setState({
    email: event.target.value,
  })
}

export default Login;
