import * as React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { ErrorPage } from '../components/Common';

const redirection = (props: any, routeProps: any) => ((localStorage.getItem('auth_token') === null) ? (
  <Redirect
    to={{
      pathname: '/admin',
      // from: routeProps.location,
    }}
  />
)
  : (props.location.pathname === '/admin/dashboard/sub-admins')
    ? (localStorage.getItem('super_admin') === 'true') ? (
      <props.component {...routeProps} />
    )
      : (
        <ErrorPage {...routeProps} homepage />
      )
    : (
      <props.component {...routeProps} />
    ));
interface IPrivateRouteProps extends RouteProps {
    component: any;
}
export const ProtectedRoute = (props: IPrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const redirect = (routeProps: any) => redirection(props, routeProps);
  return (
    <Route
      {...rest}
      render={redirect}
    />
  );
};

export default ProtectedRoute;
