import * as React from 'react';
import ReactModal from 'react-modal';
import parser from 'html-react-parser';
import CLOSE_ICON from '../../../assets/hat.png';
import './index.scss';

class Modal extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('click', this.closeModalListener, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeModalListener, true);
  }

  closeModalListener = (evt) => {
    const { modalId, autoCloseModal } = this.props;
    const flyoutElement = document.getElementById(modalId);
    let targetElement = evt.target;

    do {
      if (targetElement === flyoutElement) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);

    if (autoCloseModal) {
      this.triggerCloseModal();
    }
  }

  triggerCloseModal = () => {
    const el = document.getElementById('close-custom-modal');
    if (el) {
      el.click();
    }
  }

  render() {
    const {
      isOpen, onCancel, className, closeButtonName,
      extraClass, title, modalId, onConfirm, children,
    } = this.props;
    return (
      <div className="custom-modal-container">
        <ReactModal
          contentLabel="Modal"
          isOpen={isOpen}
          onRequestClose={onCancel}
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          className={{
            base: 'custom-modal custom-modal--full-screen',
            afterOpen: 'custom-modal_after-open',
            beforeClose: 'custom-modal_before-close',
          }}
          overlayClassName={{
            base: `custom-modal-overlay full-screen-custom-modal ${className}`,
            afterOpen: 'custom-modal-overlay_after-open',
            beforeClose: 'custom-modal-overlay_before-close',
          }}
          closeTimeoutMS={500}
        >
          <div className={`custom-modal-container ${extraClass}`}>
            <button
              type="button"
              id="close-custom-modal"
              onClick={onCancel}
              style={{ display: closeButtonName ? 'auto' : 'none' }}
            >
              <img src={CLOSE_ICON} alt="close icon" aria-hidden="true" />
            </button>
            <h4 className="custom-modal-title">{parser(title)}</h4>
            <div id={modalId}>
              {React.cloneElement(children, { onConfirm, onCancel })}
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default Modal;
