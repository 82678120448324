import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { signIn, removeError } from './actions';
import { Login } from '../../components';

const mapStateToProps = (state: any) => ({
  user: state.user.userData,
  loading: state.user.loading,
  error: state.errorHandling.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (email: string, password: string) => dispatch(signIn({ email, password })),
  removeError: () => dispatch(removeError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
