import Axios, { AxiosRequestConfig } from 'axios';
import { LocalStorage } from '../utils/LocalStorage';
import {
  ANONYMOUS_ROUTES,
  AUTH_TOKEN_EXPIRED_MESSAGE,
  AUTH_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from './constants';
import { UserRepository } from '../api/repositories/UserRepository';

const isAuthorizationTokenExpiredError = (error: any): boolean => (error.response
  && error.response.data
  && AUTH_TOKEN_EXPIRED_MESSAGE === error.response.data.data.error.message);

export const authorizationRequestInterceptor = async (config: AxiosRequestConfig):
Promise<AxiosRequestConfig> => {
  if (config.url
    && !config.url.includes(ANONYMOUS_ROUTES[0])
    && !config.url.includes(ANONYMOUS_ROUTES[1])) {
    const token: any = await LocalStorage.get(AUTH_TOKEN_STORAGE_KEY);
    config.headers = {
      ...config.headers,
      Authorization: `Token ${token}`,
    };
  }
  return config;
};

export const expiredAuthorizationTokenResponseInterceptor = async (error: any): Promise<any> => {
  const originalRequest = error.config;
  if (error.message === 'Network Error') {
    return Promise.reject({ error: { message: 'Network Error' } });
  }

  if (error.code === 'ECONNABORTED') {
    // timeout
    return Promise.reject({ error: { message: 'Network Error' } });
  }

  if (isAuthorizationTokenExpiredError(error) && !originalRequest._retry) {
    originalRequest._retry = true;
    const newToken = await UserRepository.refreshToken();
    await LocalStorage.set(AUTH_TOKEN_STORAGE_KEY, newToken.token.jwtToken);
    await LocalStorage.set(REFRESH_TOKEN_STORAGE_KEY, newToken.token.refreshToken);
    originalRequest.headers.Authorization = `Token ${newToken.token.jwtToken}`;
    const resp = await Axios(originalRequest);
    if (resp.data) {
      return Promise.resolve(resp);
    }
    return Promise.reject(resp);
  }
  return Promise.reject(error);
};

