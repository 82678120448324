import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

interface IPagination {
    page: number,
    totalCount: number,
    onPageClick: (page: number) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomPagination(props: IPagination) {
  const { page, onPageClick, totalCount } = props;
  const classes = useStyles();

  const onChange = (e: any, currPage: number) => {
    onPageClick(currPage);
  };

  return (
    <div className={classes.root}>
      <Pagination count={totalCount} page={page} onChange={onChange} />
    </div>
  );
}
