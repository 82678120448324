import React, { memo, useEffect, useState } from 'react';
import {
  Typography, makeStyles, Container, CircularProgress,
} from '@material-ui/core';
import FORM_BLANK from '../../assets/plusIcon2.svg';
import { FormCard, CustomPagination } from '../Common';
import { UI_CONFIG } from '../../constants/Constants';

const useStyles = makeStyles(() => ({
  cont: {
    backgroundColor: '#f1f3f4',
    '& *': {
      fontFamily: 'Muli !important',
    },
  },
  recentCont: {
    backgroundColor: '#fff',
  },
  root: {
    padding: '36px 12px',
    '& *': {
      fontFamily: 'Muli !important',
    },
  },
  title: {
    flexGrow: 1,
    fontSize: '16px',
    fontWeight: 400,
    color: '#5f6368',
    paddingBottom: '12px',
  },
  formTypes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subType: {
    display: 'flex',
    flexDirection: 'row',
  },
  formType: {
    backgroundColor: 'white',
    width: '50px',
    height: '50px',
    padding: '20px',
    border: '1px solid transparent',
    cursor: 'pointer',
    '&:hover, &:active': {
      border: '1px solid var(--theme)',
    },
  },
  flexChild: {
    display: 'flex',
    flex: '33.33%',
  },
}));

const Home = (props: any) => {
  const classes = useStyles();
  const {
    history, parent, questionnaire, resetQuestionnaire, getAllQuestionnaire,
  } = props;
  const [questionnaireList, setQuestionnaireList] = useState([]);
  const [totalCount, setQuestionnaireTotalCount] = useState(0);
  const [showLoader, setLoading] = useState(true);
  const [pagelength] = useState(9);
  const { questionnaireListPageCount } = questionnaire;

  useEffect(() => {
    const { questionnaireList: childQuestionnaireList, loading } = questionnaire;
    setLoading(loading);
    if (childQuestionnaireList && childQuestionnaireList.questionnaires) {
      setQuestionnaireList(childQuestionnaireList.questionnaires);
    }
    if (childQuestionnaireList && childQuestionnaireList.total) {
      setQuestionnaireTotalCount(childQuestionnaireList.total);
    }
  }, [questionnaire, setQuestionnaireList]);

  const onNewForm = () => {
    resetQuestionnaire();
    history.push(`${parent}/new`);
  };

  const onPageClick = (page: number) => {
    getAllQuestionnaire(page);
  };
  /* eslint-disable */
  return (
    <>
      <div className={classes.cont}>
        <Container maxWidth="md" className={classes.root}>
          <Typography
            className={classes.title}
            variant="h6"
          >
            Create a new questionnaire
          </Typography>
          <div className={classes.formTypes}>
            <div className="subType">
              <img
                role="link"
                tabIndex={0}
                onKeyDown={onNewForm}
                onClick={onNewForm}
                className={classes.formType}
                alt="New questionnaire"
                src={FORM_BLANK}
              />
              <div title="Blank">Blank</div>
            </div>
          </div>
        </Container>
      </div>
      <Container maxWidth="md" className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          style={{
            fontWeight: 700,
          }}
        >
          Recent Questionnaires
        </Typography>
        <div className={classes.formTypes}>
          {
                    !showLoader ? Array.isArray(questionnaireList)
                        && questionnaireList.map((d, index) => (
                          <div
                            className={classes.flexChild}
                            key={`form-card-${index}`}
                          >
                            <FormCard {...d} />
                          </div>
                        ))
                      : <CircularProgress size={UI_CONFIG.loaderSize} />
                }
        </div>
        {
          Math.ceil(totalCount / pagelength) >= 2 ?
          <CustomPagination
          page={questionnaireListPageCount}
          onPageClick={onPageClick}
          totalCount={Math.ceil(totalCount / pagelength)}
        /> : null
        }
      </Container>
    </>
  );
};

export default memo(Home);
