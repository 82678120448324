import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ERROR_MESSAGE, VERIFICATION_MESSAGE } from '../../../constants/UserConstants';
import { ERR_FORBIDDEN } from '../../../network/constants';
import { API_ERROR_MESSAGE } from '../../../constants/Constants';

interface IProps {
    showModal: boolean;
    handleYes: () => void;
    parsingError?: boolean;
    invalidEmail?: boolean;
    missingFields?: string[];
    invalidEmails?: string[];
    errorMessage?: string;
    sameLanguageParsingError?: boolean;
}

function getValues(name: string, index: number, newSet: Set<any>) {
  if (!newSet.has(name)) {
    newSet.add(name);
    return <li key={index}>{name}</li>;
  }
  return null;
}

export default function ApiErrorModal(props: IProps) {
  const newSet = new Set();
  const {
    errorMessage, showModal, parsingError,
    sameLanguageParsingError, invalidEmail, missingFields, invalidEmails,
    handleYes,
  } = props;
  return (
    <Dialog
      open={showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{ERROR_MESSAGE.error}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorMessage ? errorMessage === ERR_FORBIDDEN
            ? API_ERROR_MESSAGE.notAuthorized
            : errorMessage === API_ERROR_MESSAGE.downloadFailed
              ? API_ERROR_MESSAGE.downloadFailed : API_ERROR_MESSAGE.noPermissions
            : parsingError ? ERROR_MESSAGE.parsingError
              : sameLanguageParsingError
                ? ERROR_MESSAGE.sameLanguageError : invalidEmail
                  ? ERROR_MESSAGE.invalidEmails : ERROR_MESSAGE.apiError}
        </DialogContentText>
      </DialogContent>
      {
                (parsingError && missingFields)
                  ? (
                    <div>
                      <ul>
                        {missingFields
                          .map((name: string, index: number) => getValues(name, index, newSet))}
                      </ul>
                    </div>
                  )
                  : (
                    <div>
                      <ul>
                        {
                          invalidEmails
                          && invalidEmails
                            .map((name: string, index: number) => getValues(name, index, newSet))
                        }
                      </ul>
                    </div>
                  )
            }
      <DialogActions>
        <Button onClick={handleYes} className="button_action">
          {VERIFICATION_MESSAGE.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
