import { all } from 'redux-saga/effects';
import { watchSignInActions } from '../container/Login/saga';
import { watchQuestionnaireActions } from '../container/Questionnaire/saga';

export default function* rootSaga(): any {
  yield all([
    watchSignInActions(),
    watchQuestionnaireActions(),
  ]);
}
