import React from 'react';
import Logo from '../../../assets/WF-Logo-Color.png';
import { HEADING } from '../../../constants/UserConstants';
import './index.scss';

export const Header = () => (
  <div className="dashboard-header">
    <div className="left">
      <img src={Logo} alt="Wadhwani-opp-logo" className="logo-image" />
      <div className="divider-line" />
      <h2>{HEADING.mainHeader}</h2>
    </div>
  </div>
);
