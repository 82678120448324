import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { IApiClientErrorDetails } from '../../network/apiClientError';
import {
  Header, Home, NewQuestionnaire, FormView,
} from '../../components';
import { ErrorPage, ApiErrorModal } from '../../components/Common';
import { ERR_PERMISSIONS_NOT_FOUND, ERR_FORBIDDEN } from '../../network/constants';
import {
  logout, createQuestionnaire, getQuestions, getAllQuestionnaire,
  putQuestionnaire, resetQuestionnaire, getQuestionnaire,
} from './actions';
import { ROUTE_NAME } from '../../constants/UserConstants';

interface IProps {
    history: {
        push: (url: string) => void,
    };
    match: {
        url: string;
    };
    selected: {
        index: number,
    };
    location: {
        pathname: string;
    };
    showProgress: boolean;
    logout: () => void;
    getAllQuestionnaire: (pageCount: number) => void;
    createQuestionnaire: (payload: any) => void;
    error: {
        details: IApiClientErrorDetails;
    };
}

class Questionnaire extends Component<IProps> {
    PARENT_ROUTE = ROUTE_NAME.mainRoute;

    componentDidMount() {
      const { getAllQuestionnaire: childGetAllQuestionnaire } = this.props;
      childGetAllQuestionnaire(1);
    }

    private handleLogOut = () => {
      localStorage.clear();
      this.props.logout();
      this.props.history.push('/admin');
    }

    public render() {
      const { history } = this.props;
      const home = () => <Home {...this.props} parent={this.PARENT_ROUTE} />;
      const formView = () => <FormView {...this.props} />;
      return (
        <div>
          <Header logout={this.handleLogOut} history={history} />
          <div className="content_body">
            <div
              className={classNames('display-loader', {
                active: this.props.showProgress,
              })}
            >
              <LinearProgress color="secondary" className="loader" />
            </div>
            <Switch>
              <Route
                path={`${this.props.match.url}`}
                exact
                component={home}
              />
              <Route
                path={`${this.props.match.url}/new`}
                exact
                component={NewQuestionnaire}
              />
              <Route
                path={`${this.props.match.url}/edit`}
                exact
                component={NewQuestionnaire}
              />
              <Route
                path={`${this.props.match.url}/:id`}
                exact
                component={formView}
              />
              <Route
                path="*"
                exact
                component={ErrorPage}
              />
            </Switch>
          </div>
          {
                    (this.props.error
                        && (this.props.error.details.message === ERR_PERMISSIONS_NOT_FOUND
                            || localStorage.getItem('notAdmin') === 'true'))
                    && (
                    <ApiErrorModal
                      showModal
                      handleYes={this.handleLogOut}
                      errorMessage={this.props.error.details.message === ERR_PERMISSIONS_NOT_FOUND
                        ? ERR_PERMISSIONS_NOT_FOUND : ERR_FORBIDDEN}
                    />
                    )
                }
        </div>
      );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logout()),
  createQuestionnaire: (payload: any) => dispatch(createQuestionnaire(payload)),
  getQuestions: (payload: any) => dispatch(getQuestions(payload)),
  getQuestionnaire: (payload: any) => dispatch(getQuestionnaire(payload)),
  getAllQuestionnaire: (pageCount: number) => dispatch(getAllQuestionnaire(pageCount)),
  putQuestionnaire: (payload: any) => dispatch(putQuestionnaire(payload)),
  resetQuestionnaire: () => dispatch(resetQuestionnaire()),
});

const mapStateToProps = (state: any) => ({
  error: state.errorHandling.error,
  questionnaire: state.questionnaire,
});

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
